<template>
  <div>
    <div class="flex-between">
      <div class="title">{{ $t('accountInformation') }}</div>
      <div class="flex-row">
        <!-- <i class="el-icon-message message" style="margin: 0 10px"></i>
        <span class="msg-num">0</span>-->
      </div>
    </div>
    <div class="flex-between info">
      <div class="flex-row">
        <img src="../../static/img/user.png" />
        <div class="info-text">
          <span>{{ $t('name') }}{{ investInfo.username }}</span>
          <span style="margin-left: 200px"
            >{{ $t('state')
            }}{{
              investInfo.approved === auth.UNKNOWN.value
                ? $t('responseStatus.unknown')
                : investInfo.approved === auth.CONFIRM.value
                ? $t('responseStatus.confirm')
                : investInfo.approved === auth.PROCESSING.value
                ? $t('responseStatus.processing')
                : investInfo.approved === auth.REJECT.value
                ? $t('responseStatus.reject')
                : $t('responseStatus.reject')
            }}</span
          >
          <div>
            {{ $t('identity')
            }}{{
              investInfo.ivDetail &&
              investInfo.ivDetail.investorType === symbol.ORGANIZATION.value
                ? $t('investorTypeRadio.institutionalInvestor')
                : $t('investorTypeRadio.individualInvestor')
            }}
          </div>
        </div>
      </div>
      <div>
        <el-button class="btn-black" @click="toDetails">{{
          $t('see')
        }}</el-button>
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-wrap">
        <div class="flex-between title-all">
          <div class="title">{{ $t('investmentOverview') }}</div>
          <div class="detail" @click="$router.push({ path: '/my-invest' })">
            {{ $t('checkDetails') }}
          </div>
        </div>
        <chart-pie
          :title="$t('TotalPlatform') + overviewObject.proSum"
          :chartData="overviewObject.proNum"
          :gotoURL="'/my-invest'"
        />
      </div>
      <div class="flex-wrap" style="margin-left: 100px">
        <div class="flex-between title-all">
          <div class="title">{{ $t('assetOverview') }}</div>
          <div
            class="detail"
            style="display: none"
            @click="$router.push({ path: '/invest-all/detail' })"
          >
            {{ $t('checkDetails') }}
          </div>
        </div>
        <chart-pie
          :title="
            $t('totalAssets') +
            toThousandFilterTwo(overviewObject.assetsSum) +
            ' ' +
            $t('millionDollars')
          "
          :chartData="overviewObject.assetsNum"
        />
      </div>
    </div>
    <div class="title">{{ $t('revenueOverview') }}</div>
    <div class="flex-row top-view">
      <div class="flex-column-center">
        <div>{{ $t('accumulatedIncome') }}</div>
        <div class="text-org">{{ overviewObject.profitSum | toThousandFilterTwo }}</div>
      </div>
      <div class="flex-column-center">
        <div>{{ $t('amountReceived') }}</div>
        <div class="text-org">{{ overviewObject.toBePaid | toThousandFilterTwo }}</div>
      </div>
    </div>
    <div class="title">{{ $t('titleI18n.AccountWallet') }}</div>
    <div class="flex-between info">
      <div class="flex-wrap">
        <span>{{ $t('unitPage.totalAccount') }}</span>
        <span class="msg-num">{{
            (investInfo.unitToken
            ? Number(investInfo.unitToken).toFixed(2)
            : '0.00') | toThousandFilterTwo
        }}</span
        >({{ $t('dollar') }})
      </div>
      <el-button
        class="btn-black"
        @click="$router.push({ path: '/invest-tr/recharge' })"
        >{{ $t('btnI18n.Deposit') }}</el-button
      >
      <el-button
        class="btn-black"
        @click="$router.push({ path: '/invest-tr/withdrawal' })"
        >{{ $t('btnI18n.Withdrawal') }}</el-button
      >
    </div>
    <div class="flex-between">
      <div class="title">{{ $t('latestProduct') }}</div>
      <el-button
        class="btn-black"
        @click="$router.push({ path: '/invest-find' })"
        >{{ $t('all') }}</el-button
      >
    </div>
    <div class="flex-row">
      <div class="pro-item" v-for="(item, index) in tableData" :key="index">
        <div>{{ $t(item.user && item.user.username) }}</div>
        <div>
          {{ $t('financingAmount') }}{{ item.financingAmount | toThousandFilterTwo }}
          {{ $t('millionDollars') }}
        </div>
        <div>
          {{ $t('yield')
          }}{{ item.interestRate ? item.interestRate + '%' : '0%' }}
        </div>
        <div>
          {{ $t('amountRaised') }}{{ item.investAmount | toThousandFilterTwo }}
          {{ $t('millionDollars') }} ({{ item.progress }})
        </div>
        <div>{{ $t('financingDeadline') }}{{ item.deadlineFundRaising }}</div>
        <el-button class="btn-black" @click="toProDetail(item)">{{
          $t('see')
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import chartPie from '../../components/chart-pie';
import { differenceInDays } from 'date-fns';
import BigNumber from 'bignumber.js';
import i18n from '@/plugins/i18n';
export default {
  components: {
    chartPie,
  },
  data() {
    return {
      auth: this.$enums.AUTH,
      symbol: this.$enums.INVESTORTYPE,
      overviewObject: {
        proSum: 0, // 账户投资项目数
        proNum: [], // 账户投资项目数
        assetsSum: 0, // 资产总额
        assetsNum: [], // 资产总额
        profitSum: 0,
        toBePaid: 0,
      },
      totalAmount: 0,
      accountAmount: 0,
      investInfo: {},
      primaryData: [],
      tableData: [],
      address: '',
    };
  },
  mounted() {
    this.pageInit();
    // 获取最新项目推荐
    this.getTableList();
  },
  methods: {
    toThousandFilterTwo(num) {
      if (!num) return "0.00";
      num = Number(num).toFixed(2);
      const reNum = (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
      if (reNum.indexOf(".") > -1) return reNum;
      return reNum + ".00";
    },
    pageInit() {
      this.$axios
        .get('/v1/invest/overview')
        .then((result) => {
          if (result.code === 0 || result.code === '0') {
            this.investInfo = result.data.userRes;
            // 投资总览
            const investOverview = result.data.investNumber;
            // 资产总览
            const assetsOverview = result.data.userAmount;
            this.overviewObject.proSum = investOverview.count; // 账户投资项目数
            this.overviewObject.proNum = []; // 账户投资项目数
            this.overviewObject.assetsSum = assetsOverview.sumAmount
              ? (
                  Number(assetsOverview.sumAmount) / this.$enums.UNIT_MILLION
                ).toFixed(2)
              : '0.00'; // 资产总额
            this.overviewObject.assetsNum = []; // 资产总额
            this.overviewObject.toBePaid = this.investInfo.awaitIncome
              ? Number(this.investInfo.awaitIncome).toFixed(2)
              : '0.00'; // 待收益
            this.overviewObject.profitSum = this.investInfo.cumulativeIncome
              ? Number(this.investInfo.cumulativeIncome).toFixed(2)
              : '0.00'; // 累计收益

            // 图形数据
            this.overviewObject.proNum = [
              {
                name:
                  `${this.$t('itemsAppointment')} ` +
                  investOverview.reservationCount,
                value: investOverview.reservationCount,
              },
              {
                name:
                  `${this.$t('itemsRevenue')} ` + investOverview.incomeCount,
                value: investOverview.incomeCount,
              },
              {
                name:
                  `${this.$t('closedProjects')} ` +
                  investOverview.completedCount,
                value: investOverview.completedCount,
              },
            ];
            this.overviewObject.assetsNum = [
              {
                name:
                  `${this.$t('availableBalance')} ` +
                  (
                    Number(assetsOverview.balance) / this.$enums.UNIT_MILLION
                  ).toFixed(2) +
                  ` ${this.$t('unitI18n.Millions')}`,
                value: (
                  Number(assetsOverview.balance) / this.$enums.UNIT_MILLION
                ).toFixed(2),
              },
              {
                name:
                  `${this.$t('amountInvested')} ` +
                  (
                    Number(assetsOverview.totalInvestment) /
                    this.$enums.UNIT_MILLION
                  ).toFixed(2) +
                  ` ${this.$t('unitI18n.Millions')}`,
                value: (
                  Number(assetsOverview.totalInvestment) /
                  this.$enums.UNIT_MILLION
                ).toFixed(2),
              },
              {
                name:
                  `${this.$t('frozenAmount')} ` +
                  (
                    Number(assetsOverview.totalFrozen) /
                    this.$enums.UNIT_MILLION
                  ).toFixed(2) +
                  ` ${this.$t('unitI18n.Millions')}`,
                value: (
                  Number(assetsOverview.totalFrozen) / this.$enums.UNIT_MILLION
                ).toFixed(2),
              },
            ];
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => console.log(error));
    },
    getTableList() {
      const data = {
        page: 1,
        pageSize: 3,
      };
      this.$axios
        .get('/v1/invest/financing-orders', { params: data })
        .then((response) => {
          if (response.code === 0 || response.code === '0') {
            this.tableData = response.data.rows || [];
            this.tableTotal = response.data.count || 0;
            this.tableData.map((item) => {
              item.financingAmount = item.financingAmount
                ? Number(item.financingAmount) / this.$enums.UNIT_MILLION
                : '0.00';
              item.investAmount = item.investAmount
                ? Number(item.investAmount) / this.$enums.UNIT_MILLION
                : '0.00';
              item.progress =
                (Number(item.investAmount) / Number(item.financingAmount)) * 100
                  ? (
                      (Number(item.investAmount) /
                        Number(item.financingAmount)) *
                      100
                    ).toFixed(2) + '%'
                  : '0%';
            });
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toProDetail(row) {
      this.$router.push({
        path: '/invest-find/pro-detail',
        query: { id: row.id },
      });
    },
    toDetails() {
      this.$router.push({ path: '/invest-all/investor-detail' });
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 30px;
  color: #81d8d0;
}
.msg-num {
  color: #81d8d0;
  margin: 0 4px;
}
.info {
  border-bottom: 1px solid #979797;
  padding-bottom: 10px;
  .info-text {
    margin-left: 20px;
    line-height: 30px;
  }
}
.top-view {
  height: 90px;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  > div {
    flex: 1;
  }
  > div:nth-child(2) {
    border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }
  .text-org {
    font-size: 28px;
    font-weight: 600;
    color: #81d8d0;
    line-height: 10px;
    margin-top: 12px;
  }
}
.title-all {
  // border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  .detail {
    color: #81d8d0;
    line-height: 10px;
    &:hover {
      cursor: pointer;
      color: #ef6c00;
    }
  }
}
.pro-item {
  width: 320px;
  padding: 14px 30px;
  border-radius: 10px;
  border: 1px solid #979797;
  margin-left: 20px;
  line-height: 30px;
  .btn-black {
    display: block;
    padding: 8px 30px;
    margin: 20px auto 0;
  }
}
</style>
